import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
import './index.css'
import MyEntryCollection from './core/EntryCollection'
import App from './App'

// import * as serviceWorker from './serviceWorker'




(async () => {

  // loads data from ImmortalDB
  await MyEntryCollection.load()

  ReactDOM.render(
    // <React.StrictMode>
      <App />
    // </React.StrictMode>
    ,
    document.getElementById('root')
  );
})()



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
